<template>
  <main class="site-main">

    <!--상단 타이틀 영역 s-->
    <div class="top-level-page-area top-level-page-area-04">
      <div class="bms-relaunch-comp-v2">
        <h1 class="page-header">사회공헌활동</h1>
        <div class="feature-body">
          <p>지역을 넘어서, 한계를 넘어서, 모두가 건강한 사회를 만들어갑니다.</p>
        </div>
      </div>
    </div>
    <!--상단 타이틀 영역 e-->

    <div class="container">

      <!--Breadcrumb 영역 s-->
      <div class="top-nav-container">
        <nav role="navigator">
          <ul class="breadcrumbs list-unstyled hide-sm">
            <li class="breadcrumb"><a href="#">BMS 소식</a></li>
            <li class="breadcrumb">사회공헌활동</li>
          </ul>
        </nav>
      </div>
      <div class="section">

        <!--본문 영역 s-->
        <content-area
            v-bind="board"
            file-name="BMSON-사회공헌활동"
            :bookmark-id="$route.params.socialContributeNo"/>
        <!--본문 영역 e-->

        <!--list view 버튼-->
        <div class="btn_area_right btn_listview">
          <router-link to="../" append><button>List View</button></router-link>
        </div>

      </div>

    </div>
  </main>
</template>

<script>
import ContentArea from "@/components/common/ContentArea";

export default {
  name: "SocialContributeView",
  components: {ContentArea},
  created() {
    this.initBoard();
  },
  data() {
    return {
      board: {
        title: ''
      },
    }
  },
  methods: {
    initBoard() {
      this.$store.dispatch('initBoard', {
        id: this.$route.params.socialContributeNo,
        type: 'social-contribute'
      }).then(board => {
        this.board = board
      })
    }
  },
}
</script>

<style scoped>

</style>
